import { inject, Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { IUltraCloudAuthResponse, IUser, UserFactoryService } from '@ultra/core/models';
import { UltraosApiService } from '@ultra/core/services';
import { UserFacadeService } from '@ultra/core/stores';
import { APP_CONFIG, IEnvironment } from '@ultra/environment';
export interface IUltraCloudSession {
  sessionId: string;
  dashboardURL: string;
}
@Injectable({
  providedIn: 'root',
})
export class UltraCloudLoginService {
  private ultraosApiService: UltraosApiService = inject(UltraosApiService);
  private userService: UserFactoryService = inject(UserFactoryService);
  private userFacadeService: UserFacadeService = inject(UserFacadeService);
  private environment: IEnvironment = inject(APP_CONFIG);

  access(): Observable<IUltraCloudSession> {
    return this.userFacadeService.user$.pipe(
      first((user: IUser) => !!user.id),
      map((user: IUser) => {
        if (user && user.professionalData && user.professionalData[0] && user.professionalData[0].companyId) {
          return user;
        } else {
          return throwError('No company associated');
        }
      }),
      switchMap(() => this.getUltraCloudSession()),
      switchMap((data: IUltraCloudSession) => this.injectCookie(data)),
    );
  }

  /**
   * Generate an access cookie to sign-in to the BrainCloud application.
   * @private
   */
  private getCookie(): Observable<IUltraCloudAuthResponse> {
    return this.userService.strategy.ultraCloudLogin();
  }

  /**
   * Get the last cookie stored for the selected user and check its validity
   * @private
   */
  private getUltraCloudSession(): Observable<IUltraCloudSession> {
    return this.getCookie().pipe(
      map((response) => ({
        sessionId: response.jSessionId,
        dashboardURL: response.location,
      })),
    );
  }

  /**
   * Set a cookie in the BrainCloud domain
   * @private
   */
  private injectCookie(ultraCloudSession: IUltraCloudSession): Observable<IUltraCloudSession> {
    const brainCloudUrl = this.environment.ultraCloudPlatform;
    const brainCloudDomain = new URL(brainCloudUrl).hostname;
    const cookie = {
      url: brainCloudUrl,
      domain: brainCloudDomain,
      name: 'JSESSIONID',
      value: ultraCloudSession.sessionId,
      httpOnly: true,
      secure: true,
    };
    return from(this.ultraosApiService.setCookie(cookie)).pipe(map(() => ultraCloudSession));
  }
}
